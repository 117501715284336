import {
  API_URL,
  API_URL_UPDATED,
  APP_GUID,
  APP_NAME,
  APP_VERSION,
  VENUE_GUID,
} from "../constants";
import api, { updateHeaders } from "./api";

//const { profile } = useSelector((state) => state.profile)

class DataService {
  app_info = {
    venue_guid: VENUE_GUID,
    app_name: APP_NAME,
    app_version: APP_VERSION,
    app_guid: APP_GUID,
  };

  // async getOperatingHoursByRowGuid(payload = {}) {
  //   return api.post(
  //     API_URL + "operatinghours-module/get-holiday-hours-by-row-guid",
  //     {
  //       request: payload,
  //       app_info: this.app_info,
  //     }
  //   );
  // }

  async getHolidayHoursByRowGuid(payload = {}) {
    return api.post(
      API_URL + "operatinghours-module/get-holiday-hours-by-row-guid",
      {
        request: payload,
        app_info: this.app_info,
      }
    );
  }

  async updateDefaultPricing(payload = {}) {
    return api.post(API_URL + "/pricing-module/update-default-pricing", {
      request: payload,
      app_info: this.app_info,
    });
  }

  async getDefaultPricingByRowGuid(payload = {}) {
    return api.post(
      API_URL + "/pricing-module/get-default-pricing-by-row-guid",
      {
        request: payload,
        app_info: this.app_info,
      }
    );
  }

  async addDefaultHolidayHours(payload = {}) {
    return api.post(API_URL + "operatinghours-module/insert-default-hours", {
      request: payload,
      app_info: this.app_info,
    });
  }

  async updatePricingTabs(payload = {}) {
    Object.assign(payload, { app_info: this.app_info });
    return api.post(API_URL + "pricing-module/update-pricing", payload);
  }

  async setPricingInfo(payload = {}) {
    return api.post(API_URL + "/pricing-module/set-pricing", {
      request: payload,
      app_info: this.app_info,
    });
  }

  // async updatePricing(payload = {}) {
  //   Object.assign(payload, { app_info: this.app_info });
  //   return api.put(API_URL + "pricing-module/update-pricing", payload);
  // }

  async getPricingByRowGuid(payload = {}) {
    return await api.post(API_URL + "pricing-module/get-pricing-by-row-guid", {
      request: payload,
      app_info: this.app_info,
    });
  }

  async getHolidayPricingByRowGuid(payload = {}) {
    return api.post(
      API_URL + "pricing-module/get-holiday-pricing-by-row-guid",
      {
        request: payload,
        app_info: this.app_info,
      }
    );
  }

  async updateHolidayPricing(payload = {}) {
    // Object.assign(payload, { app_info: this.app_info });
    return api.post(API_URL + "pricing-module/update-holiday-pricing", {
      request: payload,
      app_info: this.app_info,
    });
  }

  // async unArchivePricing(payload = {}) {
  //   return api.put(API_URL + "pricing-module/unarchive-pricing", payload);
  // }
  //
  // async deletePricing(payload = {}) {
  //   return api.delete(API_URL + "pricing-module/delete-pricing", payload);
  // }

  /* Employee */

  async findByEmail(payload = {}) {
    return api.post(API_URL + "/employees-module/find-by-email", payload);
  }

  async getGeneric(payload = {}) {
    return api.post(API_URL + "/generic-module/get-generic", {
      request: payload,
      app_info: this.app_info,
    });
  }

  async updateGeneric(payload = {}) {
    return api.post(API_URL + "/generic-module/update-generic", {
      request: payload,
      app_info: this.app_info,
    });
  }

  async addEmployee(payload = {}) {
    return api.post(API_URL + "employees-module/insert-employee", {
      request: payload,
      app_info: this.app_info,
    });
  }

  /* Membership Types */

  async addMessage(payload = {}) {
    return api.post(API_URL + "smsmessages-module/insert-sms-messages", {
      request: payload,
      app_info: this.app_info,
    });
  }

  /* Benefit */

  async addBayType(payload = {}) {
    return api.post(API_URL + "generic-module/insert-generic", {
      request: payload,
      app_info: this.app_info,
    });
  }

  async updateGeneric(payload = {}) {
    return api.post(API_URL + "generic-module/update-generic", {
      request: payload,
      app_info: this.app_info,
    });
  }

  async getBayTypes(payload = {}) {
    return api.post(API_URL + "generic-module/get-generic", {
      request: payload,
      app_info: this.app_info,
    });
  }

  async updateBayType(payload = {}) {
    return api.post(API_URL + "generic-module/update-generic", {
      request: payload,
      app_info: this.app_info,
    });
  }

  async updateBayType(payload = {}) {
    return api.post(API_URL + "generic-module/update-generic", {
      request: payload,
      app_info: this.app_info,
    });
  }

  /* CONFIGURATION */
  async getConfiguration(payload = {}) {
    return api.post(
      API_URL_UPDATED +
        "additionalconfigurations-module/get-additional-configurations",
      payload
    );
  }

  async updateConfiguration(payload = {}) {
    return api.post(
      API_URL_UPDATED +
        "/additionalconfigurations-module/update-additional-configurations",
      payload
    );
  }

  async updateMessage(payload = {}) {
    return api.post(
      API_URL_UPDATED + "smsmessages-module/update-sms-messages",
      payload
    );
  }

  async getMessages(payload = {}) {
    return api.post(
      API_URL_UPDATED + "smsmessages-module/get-sms-messages",
      payload
    );
  }

  async getOperatingHours(payload = {}) {
    return api.post(
      API_URL_UPDATED + "operatinghours-module/get-operating-hours",
      payload
    );
  }

  //  changes in API

  async deleteOperatingHoursInfo(payload = {}) {
    return api.post(
      API_URL_UPDATED + "operatinghours-module/delete-operating-hours",
      payload
    );
  }

  async getOperatingHourDetails(payload = {}) {
    return api.post(
      API_URL_UPDATED + "operatinghours-module/get-operating-hours-details",
      payload
    );
  }

  async getOperatingHourInfo(payload = {}) {
    return api.post(
      API_URL_UPDATED + "operatinghours-module/get-operating-hours-info",
      payload
    );
  }

  async deleteHolidayHour(payload = {}) {
    return api.post(
      API_URL_UPDATED + "operatinghours-module/delete-holiday-hours",
      payload
    );
  }

  async getHolidayHourInfo(payload = {}) {
    return api.post(
      API_URL_UPDATED + "operatinghours-module/get-holiday-hours-info",
      payload
    );
  }

  async getDefaultHourInfo(payload = {}) {
    return api.post(
      API_URL_UPDATED + "operatinghours-module/get-default-hours-info",
      payload
    );
  }

  async getPricingInfo(payload = {}) {
    return api.post(
      API_URL_UPDATED + "pricing-module/get-pricing-info",
      payload
    );
  }

  async getTodaysDataForAdminDashboard(payload = {}) {
    return api.post(
      API_URL_UPDATED + "admin-module/get-todays-data-for-admin-dashboard",
      payload
    );
  }

  async getBussingReportData(payload = {}) {
    return api.post(
      API_URL_UPDATED + "bays-module/get-bussing-report-data",
      payload
    );
  }

  async deletePricingInfo(payload = {}) {
    return api.post(API_URL_UPDATED + "pricing-module/delete-pricing", payload);
  }

  async getHolidayPricingInfo(payload = {}) {
    return api.post(
      API_URL_UPDATED + "/pricing-module/get-holiday-pricing-info",
      payload
    );
  }

  async deleteHolidayPricing(payload = {}) {
    return api.post(
      API_URL_UPDATED + "/pricing-module/delete-holiday-pricing ",
      payload
    );
  }

  /* end  */

  async updateOperatingHoursInfo(payload = {}) {
    return api.post(
      API_URL_UPDATED + "operatinghours-module/update-operating-hours",
      payload
    );
  }

  async addOperatingHours(payload = {}) {
    return api.post(
      API_URL_UPDATED + "operatinghours-module/insert-operating-hours",
      payload
    );
  }

  async getDefaultOperatingHours(payload = {}) {
    return api.post(
      API_URL_UPDATED + "operatinghours-module/get-default-hours",
      payload
    );
  }

  async updateDefaultOperatingHoursInfo(payload = {}) {
    return api.post(
      API_URL_UPDATED + "operatinghours-module/update-default-hours",
      payload
    );
  }

  async getMembershipTypes(payload = {}) {
    return api.post(
      API_URL_UPDATED + "membershiptypes-module/get-membership-types",
      payload
    );
  }

  async updateMembershipTypes(payload = {}) {
    return api.post(
      API_URL_UPDATED + "membershiptypes-module/update-membership-type",
      payload
    );
  }

  async addMembershipTypes(payload = {}) {
    return api.post(
      API_URL_UPDATED + "membershiptypes-module/insert-membership-type",
      payload
    );
  }

  async getBenefits(payload = {}) {
    return api.post(API_URL_UPDATED + "benefits-module/get-benefits", payload);
  }

  async getMembershipBenefits(payload = {}) {
    return api.post(
      API_URL_UPDATED + "/membershiptypes-module/get-membership-benefits",
      payload
    );
  }

  async updateMembershipBenefits(payload = {}) {
    return api.post(
      API_URL_UPDATED +
        "membershiptypes-module/insert-or-update-membersip-benefits",
      payload
    );
  }

  async updateBenefits(payload = {}) {
    return api.post(
      API_URL_UPDATED + "benefits-module/update-benefit",
      payload
    );
  }

  async addBenefits(payload = {}, uuid = "") {
    return api.post(
      API_URL_UPDATED + "benefits-module/insert-benefit",
      payload
    );
  }

  async getOperatingHourDetails(payload = {}) {
    return api.post(
      API_URL_UPDATED + "operatinghours-module/get-operating-hours-details",
      payload
    );
  }

  async updateOperatingHoursDetails(payload = {}) {
    return api.post(
      API_URL_UPDATED + "operatinghours-module/update-operating-hours-details",
      payload
    );
  }

  async getHolidayHours(payload = {}) {
    return api.post(
      API_URL_UPDATED + "operatinghours-module/get-holiday-hours",
      payload
    );
  }

  async updateHolidayHoursInfo(payload = {}) {
    return api.post(
      API_URL_UPDATED + "operatinghours-module/update-holiday-hours",
      payload
    );
  }

  async addHolidayHours(payload = {}) {
    return api.post(
      API_URL_UPDATED + "operatinghours-module/insert-holiday-hours",
      payload
    );
  }

  async getHolidayHoursDetails(payload = {}) {
    return api.post(
      API_URL_UPDATED + "operatinghours-module/get-holiday-hours-details",
      payload
    );
  }

  async updateHolidayHoursDetails(payload = {}) {
    return api.post(
      API_URL_UPDATED + "operatinghours-module/update-holiday-hours-details",
      payload
    );
  }

  async getDefaultHolidayDetails(payload = {}) {
    return api.post(
      API_URL_UPDATED + "operatinghours-module/get-default-hours-details",
      payload
    );
  }

  async updateDefaultOperatingHoursDetails(payload = {}) {
    return api.post(
      API_URL_UPDATED + "operatinghours-module/update-default-hours-details",
      payload
    );
  }

  /* Pricing Module */

  async getPricing(payload = {}) {
    return api.post(API_URL_UPDATED + "pricing-module/get-pricing", payload);
  }

  async getPricingDetails(payload = {}) {
    return api.post(
      API_URL_UPDATED + "/pricing-module/get-pricing-details",
      payload
    );
  }

  async updatePricing(payload = {}) {
    return api.post(API_URL_UPDATED + "pricing-module/update-pricing", payload);
  }

  async updatePricingDetails(payload = {}) {
    return api.post(
      API_URL_UPDATED + "pricing-module/update-pricing-details",
      payload
    );
  }

  async addPricing(payload = {}) {
    return api.post(API_URL_UPDATED + "pricing-module/insert-pricing", payload);
  }

  /* Holiday Pricing */

  async getHolidayPricing(payload = {}) {
    return api.post(
      API_URL_UPDATED + "/pricing-module/get-holiday-pricing",
      payload
    );
  }

  async getHolidayPricingDetails(payload = {}) {
    return api.post(
      API_URL_UPDATED + "/pricing-module/get-holiday-pricing-details",
      payload
    );
  }

  async updateHolidayPricing(payload = {}) {
    return api.post(
      API_URL_UPDATED + "pricing-module/update-holiday-pricing",
      payload
    );
  }

  async updateHolidayPricingDetails(payload = {}) {
    return api.post(
      API_URL_UPDATED + "pricing-module/update-holiday-pricing-details",
      payload
    );
  }

  async addHolidayPricing(payload = {}) {
    return api.post(
      API_URL_UPDATED + "pricing-module/insert-holiday-pricing",
      payload
    );
  }

  /* Default Pricing */

  async getDefaultPricing(payload = {}) {
    return api.post(
      API_URL_UPDATED + "/pricing-module/get-default-pricing",
      payload
    );
  }

  async getDefaultPricinginfo(payload = {}) {
    return api.post(
      API_URL_UPDATED + "/pricing-module/get-default-pricing-info",
      payload
    );
  }
  async getDefaultPricingDetails(payload = {}) {
    return api.post(
      API_URL_UPDATED + "/pricing-module/get-default-pricing-details",
      payload
    );
  }

  async updateDefaultPricing(payload = {}) {
    return api.post(
      API_URL_UPDATED + "/pricing-module/update-default-pricing",
      payload
    );
  }

  async updateDefaultPricingDetails(payload = {}) {
    return api.post(
      API_URL_UPDATED + "pricing-module/update-default-pricing-details",
      payload
    );
  }

  async getBayType(payload = {}) {
    return api.post(
      API_URL_UPDATED + "bays-module/get-bay-types", // is default
      payload
    );
  }

  async getDaysOfWeek(payload = {}) {
    return api.post(
      API_URL_UPDATED + "pricing-module/get-days-of-the-week", // is default
      payload
    );
  }

  async getDayPart(payload = {}) {
    return api.post(
      API_URL_UPDATED + "pricing-module/get-day-parts", // is default
      payload
    );
  }

  async getDuration(payload = {}) {
    return api.post(
      API_URL_UPDATED + "membershiptypes-module/get-duration", // is default
      payload
    );
  }

  /* Employee */

  async getEmployee(payload = {}) {
    return api.post(
      API_URL_UPDATED + "employees-module/get-employees",
      payload
    );
  }

  async updateEmployee(payload = {}) {
    return api.post(
      API_URL_UPDATED + "employees-module/update-employee-avatar",
      payload
    );
  }

  async getAvatar(payload = {}) {
    return api.post(API_URL_UPDATED + "employees-module/get-avatars", payload);
  }

  async getAppAccessSingle(payload = {}) {
    return api.post(
      API_URL_UPDATED + "appsecurity-module/get-app-access",
      payload
    );
  }

  async getEmployeeByRowGuid(payload = {}) {
    return api.post(
      API_URL_UPDATED + "employees-module/get-employee-details",
      payload
    );
  }

  async getAppAccessAwait(payload = {}) {
    const res = await api.post(
      API_URL_UPDATED + "appsecurity-module/get-app-access",
      payload
    );
    return res;
  }

  async findByEmail(payload = {}) {
    return api.post(
      API_URL_UPDATED + "/employees-module/find-by-email",
      payload
    );
  }

  /*  */
  async deleteMembershipTypes(payload = {}) {
    return api.post(
      API_URL_UPDATED + "membershiptypes-module/delete-membership-type",
      payload
    );
  }
  async getMembershipDetails(payload = {}) {
    return api.post(
      API_URL_UPDATED + "membershiptypes-module/get-membership-details",
      payload
    );
  }
  async getBenefitDetails(payload = {}) {
    return api.post(
      API_URL_UPDATED + "benefits-module/get-benefits-details",
      payload
    );
  }
  async deleteBenefit(payload = {}) {
    return api.post(
      API_URL_UPDATED + "benefits-module/delete-benefit",
      payload
    );
  }

  /* Automation */
  async getAutomation(payload = {}) {
    return api.post(
      API_URL_UPDATED + "bays-module/get-automation-for-venue",
      payload
    );
  }
  async setAutomation(payload = {}) {
    return api.post(
      API_URL_UPDATED + "admin-module/set-automation-for-venue",
      payload
    );
  }
  async getVenuePauseStatus(payload = {}) {
    return api.post(
      API_URL_UPDATED + "bays-module/get-venue-pause-status",
      payload
    );
  }
  async changeVenuePause(payload = {}) {
    return api.post(
      API_URL_UPDATED + "bays-module/change-venue-pause",
      payload
    );
  }
}

export default new DataService();
