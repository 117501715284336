import { configureStore } from "@reduxjs/toolkit";
import offCanvesReducer from "../features/offCanvesSlice";
import showContentReducer from "../features/showContentSlice";
import showAccountFormReducer from "../features/showAccountFormSlice";
import reloadDataTableReducer from "../features/reloadDataTableSlice";
import operatingHoursReducer from "../features/operatingHoursSlice";
import profileReducer from "../features/profileSlice";
import loaderReducer from "../features/loaderSlice";
import automationReducer from "../features/automationSlice";
import venuePauseReducer from '../features/venuePauseSlice';
export const store = configureStore({
  reducer: {
    offcanves: offCanvesReducer,
    accountForm: showAccountFormReducer,
    datatableReload: reloadDataTableReducer,
    showContent: showContentReducer,
    operatingHours: operatingHoursReducer,
    profile: profileReducer,
    loader: loaderReducer,
    automation: automationReducer,
    venuePause: venuePauseReducer,
  },
});
