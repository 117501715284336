import axios from "axios";
import TokenService from "./token.service";
import authService from "./auth.service";

const cancelTokenSource = axios.CancelToken.source();

import {
    API_URL,
    APP_GUID,
    APP_NAME,
    APP_VERSION, LOGOUT_VARNAME,
    VENUE_GUID,
} from "../constants";
import { checkExpTime, checkTokenExpiration } from "../commonFunctions";
import { toast } from "react-toastify";
import { msalInstance } from "../index";

const instance = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        VenueGUID: VENUE_GUID,
        AppName: APP_NAME,
        AppVersion: APP_VERSION,
        AppGUID: APP_GUID,
    },
});

const updateHeaders = (value) => {
    instance.defaults.headers.common = {
        ...instance.defaults.headers.common,
        TransactionGuid: value,
    };
};

const removeHeaders = () => {
    const headers = { ...instance.defaults.headers.common };
    if (headers.TransactionGuid) {
        delete headers.TransactionGuid;
        instance.defaults.headers.common = headers;
    }
};


instance.interceptors.request.use(
    (config) => {

  
      const username = TokenService.getUserName();
      const token = TokenService.getLocalAccessToken();
      const expireTokenTime =  TokenService.getExpTime();
      if (token) {
        config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
        //config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      if (username) {
        config.headers["userEmail"] = username;
      }
      return config;
      /* if (token) {
        config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
        //config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      if (username) {
        config.headers["userEmail"] = username;
      }
      return config; */
  
  
      if (!checkTokenExpiration(expireTokenTime)) {
       /*  msalInstance.logoutRedirect({
          postLogoutRedirectUri: "/login",
          account: msalInstance.getActiveAccount(),
          onRedirectNavigate: () => {
            localStorage.setItem(LOGOUT_VARNAME, Date.now().toString());
            authService.logout();
            window.location.replace("/login")
            return true;
          },
        }); */
        console.log("Access token has expired.")
       // return Promise.reject("Access token has expired.");
       return config;
      } else {
        if (token) {
          config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
          //config.headers["x-access-token"] = token; // for Node.js Express back-end
        }
        if (username) {
          config.headers["userEmail"] = username;
        }
        return config;
      }
    },
    (error) => {
      return Promise.reject(error);
    }
  );
instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        if (originalConfig) {
            if (originalConfig.url.split("/").pop() !== "login" && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;
                    /* localStorage.setItem(LOGOUT_VARNAME, Date.now().toString());
                    authService.logout();
                    window.location.replace("/login") */
                    msalInstance.logoutRedirect({
                        postLogoutRedirectUri: "/login",
                        account: msalInstance.getActiveAccount(),
                        onRedirectNavigate: () => {
                            localStorage.setItem(LOGOUT_VARNAME, Date.now().toString());
                            authService.logout();
                            window.location.replace("/login")
                            return false;
                        },
                    });
                }
            }
        }

        return Promise.reject(err);
    }
);

export default instance; // Add this line for default export
export { updateHeaders, removeHeaders, cancelTokenSource };
