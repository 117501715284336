import _ from "lodash";
import { api } from "./apiServices/api";

export const filterObject = (obj, filter, filterValue) =>
  Object.keys(obj).reduce(function (acc, val) {
    if (obj[val][filter] == filterValue) acc[val] = obj[val];
    return acc;
  }, {});

export const filterObjectByDisable = (obj, filter, filterValue) => {
  return Object.keys(obj).map(function (acc, val) {
    if (filterValue == "all") {
      obj[acc].disable = 0;
      return obj[acc];
    }
    if (obj[acc][filter] == filterValue) {
      obj[acc].disable = 0;
      return obj[acc];
    } else {
      obj[acc].disable = 1;
    }
    return obj[acc];
  }, {});
};

export const convertArrayToObject = (array, key) => {
  return _.mapKeys(array, key);
};

export const savedUsers = () => {
  const appName = localStorage.getItem(window.location.hostname);
  if (appName) {
    return JSON.parse(appName);
  }
};

export  const checkTokenExpiration = (expTime) => {
  const currentTimestamp = Math.floor(Date.now() / 1000); // Convert milliseconds to seconds
  if (currentTimestamp >= expTime) {
    return false;
    // Handle token expiration, e.g., initiate a token refresh or logout
  } else {
    return true;
    // Token is still valid, you can proceed with your application logic
  }
};

const getExpirationTime = (user) => {
  if (user.idTokenClaims && user.idTokenClaims.exp) {
    return checkTokenExpiration(user.idTokenClaims?.exp);
  } else {
    return false;
  }
};


export const  convertSecondsToTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours}h ${minutes}m ${remainingSeconds}s`;
}

export const checkExpTime = (user) => {
  if (
    window.location.pathname == "/login" ||
    window.location.pathname == "/account-selection"
  ) {
    return true;
  } else {
    const savedUsers = localStorage.getItem(window.location.hostname);
    if (user) {
      const savedUsersJson = JSON.parse(savedUsers);
      const userExists = savedUsersJson.filter((savedUser) => {
        return user?.homeAccountId == savedUser.homeAccountId ? true : false;
      });

      return userExists ? getExpirationTime(user) : false;
    }
  }
};

export const setCookie = (name, value, domain) => {
  var expires = "";
  document.cookie =
    name +
    "=" +
    (value || "") +
    expires +
    ";domain=" +
    domain +
    ";path=/;SameSite=None;secure;";
};
export const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
export const eraseCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};


export const extractTime  = (timeString) => {
  
  console.log(timeString);
  const time = new Date(timeString);
  const hours = time.getHours();
  const minutes = time.getMinutes();
  const amPM = hours >= 12 ? "pm" : "am";
  const displayHours = hours % 12 || 12;

  // Ensure minutes are properly formatted with leading zeros if necessary
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${displayHours}:${formattedMinutes} ${amPM}`
}