import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  automation: 0,
  automationChange: false,
};

export const automationSlice = createSlice({
  name: "automation",
  initialState,
  reducers: {
    // actions
    setAutomation: (state, action) => {
      if (action.payload == 1) {
        state.automation = true;
        state.automationChange = false;
      } else {
        state.automation = false;
        state.automationChange = false;
      }
    },
  },
});

export const { setAutomation } = automationSlice.actions;

export default automationSlice.reducer;
